<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">My Info</h3>
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="py-6">
              <v-row class="pb-2">
                <v-col cols="12"><h2>Account Info</h2></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2">
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="USER ID"
                    class="px-1 pt-0"
                    v-model="user_frm.user_id"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="NEW PASSWORD"
                    v-model="user_password_frm.user_new_pw"
                    type="password"
                    class="px-1 pt-0"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="NEW PASSWORD REPEAT"
                    v-model="user_password_frm.user_new_pw_rep"
                    type="password"
                    class="px-1 pt-0"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pb-2">
                <v-col cols="12"><h2>User Info</h2></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-2">
                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="UESR FIRST NAME(KOR:이름)"
                    class="px-1 pt-0"
                    v-model="user_frm.user_name_first_kor"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="UESR LAST NAME(KOR:성)"
                    class="px-1 pt-0"
                    v-model="user_frm.user_name_last_kor"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="UESR FIRST NAME(ENG:이름)"
                    class="px-1 pt-0"
                    v-model="user_frm.user_name_first_eng"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="UESR LAST NAME(ENG:성)"
                    class="px-1 pt-0"
                    v-model="user_frm.user_name_last_eng"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="MAIL ID"
                    class="px-1 pt-0"
                    v-model="user_frm.user_mail_id"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="MAIL DOMAIN"
                    class="px-1 pt-0"
                    prepend-inner-icon="mdi-at"
                    v-model="user_frm.user_mail_domain"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    light
                    dense
                    hide-details
                    label="USER PHONE NUMBER"
                    class="px-1 pt-0"
                    v-model="user_frm.user_phone_number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                  <v-btn @click="saveInfo"> SAVE </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar color="#083272" dark>
              <v-toolbar-title>
                <h3 class="font-weight-bold suptitle">My Company</h3>
              </v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="py-6">
              <v-row class="pt-2">
                <v-col>
                  <v-data-table
                    :headers="companyHeader"
                    :items="user_permission_company"
                    :search="companySearch"
                    class="elevation-0"
                    dense
                  >
                    <template v-slot:item.company_level="{ item }">
                      {{
                        getLevelName(item.TB_INFO_COMPANY_LIST.company_level)
                      }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { getAllocCompany } from "@/api/Permission";
import { getCompanyLevel } from "@/api/Presets";
import { putAccount } from "@/api/Accounts";
export default {
  beforeMount() {
    this.user_frm = this.myInfo;
  },
  mounted() {
    this.getMyPermission();
    this.getCompanyLevel();
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
  },
  methods: {
    getLevelName(level) {
      const company_level = this.companyLevelList.find(
        (e) => e.company_level == level
      );
      if (company_level != null) return company_level.level_name;
      return level;
    },
    async getCompanyLevel() {
      const data = await getCompanyLevel();
      this.companyLevelList = [];
      if (data.success) {
        this.companyLevelList = data.data;
        for (const level of this.companyLevelList) {
          level.text = level.level_name;
          level.value = level.company_level;
        }
      }
    },
    async getMyPermission() {
      const data = await getAllocCompany();
      if (data.success) {
        this.user_permission_company = data.data;
      }
    },
    async saveInfo() {
      if (this.user_password_frm.user_new_pw != null) {
        if (
          this.user_password_frm.user_new_pw ==
          this.user_password_frm.user_new_pw_rep
        ) {
          this.user_frm.user_pw = this.user_password_frm.user_new_pw;
        } else {
          alert("새로운 암호 두개가 일치하지 않습니다.");
          return false;
        }
      }
      const data = await putAccount(this.user_frm);
      if (data.success) {
        alert("정보를 업데이트 했습니다. 다시 로그인 하세요!");
        localStorage.clear();
        this.$store.commit("userInfo/updateInfo");
        this.$router.push("/Login");
      } else {
        alert(data);
      }
    },
  },
  data() {
    return {
      companyLevelList: [],
      companySearch: "",
      companyHeader: [
        {
          text: "회사명",
          align: "start",
          sortable: true,
          value: "TB_INFO_COMPANY_LIST.company_name",
        },
        {
          text: "고유코드",
          align: "start",
          value: "TB_INFO_COMPANY_LIST.company_uuid",
        },
        {
          text: "회사주소",
          align: "start",
          sortable: false,
          value: "TB_INFO_COMPANY_LIST.company_addr",
        },
        {
          text: "회사권한",
          align: "start",
          sortable: false,
          value: "company_level",
        },
      ],

      user_password_frm: {
        user_new_pw: null,
        user_new_pw_rep: null,
      },
      user_frm: {
        user_idx: null,
        user_id: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
      },

      user_permission_company: [],
    };
  },
};
</script>
