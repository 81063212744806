import { requests } from "@/utils/requests";

export const putAccount = async ({
  user_pw,
  user_name_first_kor,
  user_name_last_kor,
  user_name_first_eng,
  user_name_last_eng,
  user_mail_id,
  user_mail_domain,
  user_phone_number,
}) => {
  const data = await requests({
    url: `/api/service/account/userInfo`,
    method: "PUT",
    data: {
      user_pw,
      user_name_first_kor,
      user_name_last_kor,
      user_name_first_eng,
      user_name_last_eng,
      user_mail_id,
      user_mail_domain,
      user_phone_number,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  putAccount,
};
