import { requests } from "@/utils/requests";

export const getAllocCompany = async () => {
  const data = await requests({
    url: "/api/service/account/permission/company",
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
